import React from "react";
import Showcase from "../showcase/showcase"

function Info() {
  return (
    <div className="info">
      <div className="container">
        <div className="info__content">
          <div className="info__text">
            <div className="info__title slide-from-bottom">
              <h2 className="heading-2">La tua vita, la nostra garanzia di protezione</h2>
            </div>
            <div className="info__description">
              <p className="paragraph slide-from-bottom">Sicura è una società di intermediazione assicurativa con sede a Catania. Offriamo consulenza per famiglie, pensionati e piccole imprese.</p>
              <p className="paragraph slide-from-bottom">Siamo orientati al cliente al 100%, stando attenti alle novità e ai nuovi bisogni, cercando di offrire soluzioni migliori per te.</p>
            </div>
          </div>
          <div className="info__images">
            <Showcase />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Info;
