import React from "react"
import Header from "../header/header"

function Hero() {
  return (
    <div className="hero">
      <div className="container">
        <div className="hero__content">
          <Header />
          <div className="hero__main">
            <div className="hero__text">
              <div className="hero__title">
                <h1 className="heading-1">
                  Soluzioni assicurative?<br /> Scegli <span className="u-font-bold">Sicura</span>
                </h1>
              </div>
              <div className="hero__subtitle">
                <h5 className="heading-4 heading-4--normal">Consulenza per famiglie, pensionati e piccole imprese. </h5>
              </div>
              <div className="hero__cta">
                <a href="#info" className="btn btn--accent btn--normal">Scopri di più</a>
              </div>
            </div>
            <div className="hero__illustration">
              <img src={require('../../images/logo-illustration.svg')}
                   alt="Illustrazione Sicura" className="logo-illustration"/>
            </div>
          </div>
        </div>
      </div>
      <div className="hero__caret-down">
        <a href="#info" className="hero__caret-down-link">
          <img src={require('../../images/caret-down.svg')} alt="Scroll down!" className="caret-down"/>
        </a>
      </div>
    </div>
  );
}

export default Hero;
