import React from "react";

function ContactUs() {
  return (
    <div className="contact-us">
      <div className="container">
        <div className="contact-us__content">
          <div className="contact-us__title">
            <h3 className="heading-3">Ti supportiamo in qualsiasi momento</h3>
          </div>
          <div className="contact-us__contacts">
            <div className="contact-us__contact">
              <div className="contact-us__contact-title">
                <h4 className="heading-4">Consulenza telefonica</h4>
              </div>
              <div className="contact-us__contact-subtitle">
                <span className="info-text">Siamo a tua disposizione tutti i giorni</span>
              </div>
              <div className="contact-us__contact-contacts">
                <a href="tel:+39 095 7395659" className="contact-us__link">+39 095 7395659</a>
                <a href="tel:+39 338 2728737" className="contact-us__link">+39 338 2728737</a>
              </div>
            </div>
            <div className="contact-us__contact">
              <div className="contact-us__contact-title">
                <h4 className="heading-4">Hai domande?</h4>
              </div>
              <div className="contact-us__contact-subtitle">
                <span className="info-text">Scrivici le tue domande, dubbi o suggerimenti</span>
              </div>
              <div className="contact-us__contact-contacts">
                <a href="mailto:info@sceglisicura.it" className="contact-us__link">info@sceglisicura.it</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
