import React from "react";

function Header() {
  return (
    <header className="header">
      <div className="header__logo">
        <a href="#home" className="header__logo-link">
          <img
            srcSet={`
          ${require('../../images/logo-dark.png')} 1x,
          ${require('../../images/logo-dark@2x.png')} 2x,
          ${require('../../images/logo-dark@3x.png')} 3x,
          `}
            alt="Logo di Sicura"
            className="logo"/>
        </a>
      </div>
      <div className="header__btn">
        <a href="#contact-form" className="btn btn--accent-outline btn--small btn--rounded">Richiedi chiamata</a>
      </div>
    </header>
  );
}

export default Header;
