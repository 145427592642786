import React from "react";

function Partners() {
  return (
    <div className="partners">
      <div className="container">
        <div className="partners__content">
          <div className="partners__container">
            <div className="partners__title">
              <h5 className="heading-4">I nostri partner</h5>
            </div>
            <ul className="partners__partners">
              <li className="partners__partner">
                <a
                  href="http://www.groupama.it/"
                  className="partners__link"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    srcSet={`
                  ${require('../../images/groupama.png')} 1x,
                  ${require('../../images/groupama@2x.png')} 2x,
                  ${require('../../images/groupama@3x.png')} 3x,
                `}
                    alt=""
                    className="partners__image"/>
                </a>
              </li>
              <li className="partners__partner">
                <a
                  href="https://www.nobis.it/"
                  className="partners__link"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    srcSet={`
                  ${require('../../images/nobis.png')} 1x,
                  ${require('../../images/nobis@2x.png')} 2x,
                  ${require('../../images/nobis@3x.png')} 3x,
                `}
                    alt=""
                    className="partners__image"/>
                </a>
              </li>
              <li className="partners__partner">
                <a
                  href="https://www.prima.it/"
                  className="partners__link"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    srcSet={`
                  ${require('../../images/prima.png')} 1x,
                  ${require('../../images/prima@2x.png')} 2x,
                  ${require('../../images/prima@3x.png')} 3x,
                `}
                    alt=""
                    className="partners__image"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
