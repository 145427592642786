import React from "react"

// import "../images/twitter.png";
// import "../images/facebook.png";
import Layout from "../components/layout/layout"
import Hero from "../components/hero/hero"
import Partners from "../components/partners/partners"
import Info from "../components/info/info"
import Services from "../components/services/services"
import WhyUs from "../components/whyUs/whyUs"
import ContactForm from "../components/contactForm/contactForm"
import ContactUs from "../components/contactUs/contactUs"
import Footer from "../components/footer/footer"
import SEO from "../components/seo"
import Animations from "../components/animations/animations"

const IndexPage = () => {
  return (
    <>
    <Animations />
    <Layout class="home">
      <SEO
        title="Soluzioni assicurative? Scegli Sicura."
        lang="it"
        description="Sicura è una società di intermediazione assicurativa con sede a Catania. Offriamo consulenza per famiglie, pensionati e piccole imprese."
      />
      <section className="home__hero">
        <Hero />
      </section>
      <section className="home__partners-container">
        <div className="home__partners">
          <Partners />
        </div>
      </section>
      <section id="info" className="home__info">
        <Info />
      </section>
      <section className="home__services">
        <Services />
      </section>
      <section className="home__why-us">
        <WhyUs />
      </section>
      <section id="contact-form" className="home__contact-form">
        <ContactForm />
      </section>
      <section className="home__contact-us">
        <ContactUs />
      </section>
      <Footer />
    </Layout>
    </>
)}

export default IndexPage
