import React from "react";

function ServicesList() {
  return (
    <ul className="services-list">
      <li className="services-list__element">
        <div className="services-list__icon">
          <img src={require("../../images/auto.svg")} alt="Auto" className="services-list__image"/>
        </div>
        <div className="services-list__text">
          <div className="services-list__title">
            <h4 className="heading-3">Assicurazione auto</h4>
          </div>
          <div className="services-list__description">
            <p className="paragraph">Il tuo consulente assicurativo sarà a tua disposizione in ogni fase per assicurarti di ottenere la copertura più adatta alla tua vita.</p>
          </div>
        </div>
      </li>
      <li className="services-list__element">
        <div className="services-list__icon">
          <img src={require("../../images/home.svg")} alt="Casa" className="services-list__image"/>
        </div>
        <div className="services-list__text">
          <div className="services-list__title">
            <h4 className="heading-3">Assicurazione casa</h4>
          </div>
          <div className="services-list__description">
            <p className="paragraph">Hai comprato una nuova casa, hai un mutuo? Quindi qual è il prossimo step? Assicurazione sulla casa ovviamente.</p>
          </div>
        </div>
      </li>
      <li className="services-list__element">
        <div className="services-list__icon">
          <img src={require("../../images/work.svg")} alt="Lavoro" className="services-list__image"/>
        </div>
        <div className="services-list__text">
          <div className="services-list__title">
            <h4 className="heading-3">Assicurazione lavorativa</h4>
          </div>
          <div className="services-list__description">
            <p className="paragraph">Indipendentemente dal settore in cui operi, possiamo guidarti come impostare l'assicurazione aziendale necessaria per le tue esigenze.</p>
          </div>
        </div>
      </li>
      <li className="services-list__element">
        <div className="services-list__icon">
          <img src={require("../../images/life.svg")} alt="Vita" className="services-list__image"/>
        </div>
        <div className="services-list__text">
          <div className="services-list__title">
            <h4 className="heading-3">Assicurazione vita</h4>
          </div>
          <div className="services-list__description">
            <p className="paragraph">Garantisci protezione e sicurezza economica alla tua famiglia. Gestisci al meglio gli imprevisti grandi e piccoli. Scegli le polizze assicurative più adatte a te.</p>
          </div>
        </div>
      </li>
    </ul>
  )
}

export default ServicesList;
