import React from "react"
import PropTypes from "prop-types"

function TextInput(props) {
  const handleChange = (event) => {
    props.setValue(event.target.value);
  }

  return (
    <div className="text-input">
      <input
        type={props.type}
        name={props.name}
        id={props.id}
        value={props.value}
        onChange={handleChange}
        required={props.required}
        className="text-input__input" />
      <div className={props.value !== '' ? 'text-input__label text-input__label--active' : 'text-input__label'}>
        <label htmlFor={props.name} className="text-input__label-text">{props.label}</label>
      </div>
    </div>
  );
}

TextInput.defaultProps = {
  type: 'text',
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'tel']),
}

export default TextInput;
