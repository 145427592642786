import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__main">
            <div className="footer__section">
              <div className="footer__logo">
                <img
                  srcSet={`
                  ${require('../../images/logo-dark.png')} 1x,
                  ${require('../../images/logo-dark@2x.png')} 2x,
                  ${require('../../images/logo-dark@3x.png')} 3x,
                  `}
                  alt="Logo di Sicura"
                  className="footer__image"/>
              </div>
            </div>
            <div className="footer__section">
              <div className="footer__section-title">
                <h6 className="heading-6">Agenzia</h6>
              </div>
              <div className="footer__section-unit">
                <span className="footer__text">Via Etnea 736</span>
                <span className="footer__text">95121 Catania CT</span>
              </div>
              <div className="footer__section-unit">
                <span className="footer__text">Lunedì · Venerdì</span>
                <span className="footer__text">8:30 - 13:00 · 15:00 - 18:00</span>
              </div>
            </div>
            <div className="footer__section">
              <div className="footer__section-title">
                <h6 className="heading-6">Contatti</h6>
              </div>
              <div className="footer__section-unit">
                <a href="tel:+39 095 7395659" className="footer__text">+39 095 7395659</a>
                <a href="mailto:info@sceglisicura.it" className="footer__text">info@sceglisicura.it</a>
              </div>
            </div>
            <div className="footer__section">
              <div className="footer__section-title">
                <h6 className="heading-6">Follow Us</h6>
              </div>
              <div className="footer__section-unit">
                <div className="footer__socials">
                  <a
                    href="https://www.facebook.com/sceglisicura"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__social-link">
                    <img
                      src={require('../../images/facebook.svg')}
                      alt="Seguici su Facebook"
                      className="footer__icon"/>
                  </a>
                  <a
                    href="https://www.instagram.com/sceglisicura"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer__social-link">
                    <img
                      src={require('../../images/instagram.svg')}
                      alt="Seguici su Instagram"
                      className="footer__icon"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__bottom">
            <span className="footer__link">© 2020 Sicura S.r.l</span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
