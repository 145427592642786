import React from "react";

function WhyUs() {
  return (
    <div className="why-us">
      <div className="container">
        <div className="why-us__content">
          <div className="why-us__title">
            <h2 className="heading-2">Perché scegliere Sicura</h2>
          </div>
          <div className="why-us__reasons">
            <div className="why-us__reason">
              <div className="why-us__reason-text">
                <div className="why-us__reason-title">
                  <h4 className="heading-3">Orientato al cliente al 100%</h4>
                </div>
                <div className="why-us__reason-description">
                  <p className="paragraph">I servizi offerti da Sicura si adattano a ogni cliente e corrispondono al meglio ai bisogni della persona in termini di sicurezza e tranquillità.</p>
                </div>
              </div>
            </div>
            <div className="why-us__reason">
              <div className="why-us__reason-text">
                <div className="why-us__reason-title">
                  <h4 className="heading-3">Assistenza a lungo termine</h4>
                </div>
                <div className="why-us__reason-description">
                  <p className="paragraph">Una agenzia che risponde sempre.<br />
                  Tutte le nostre proposte sono studiate per adattarsi in modo completo alle necessità della persona</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
