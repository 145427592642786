import React, { useState } from "react"
import TextInput from "../textInput/textInput"

function ContactForm() {
  const [state, setState] = useState({
    name: '',
    phone: '',
    message: '',
    loading: false,
    success: undefined,
  });

  function handleName(name) {
    setState({
      ...state,
      name,
    });
  }

  function handlePhone(phone) {
    setState({
      ...state,
      phone,
    });
  }

  function handleMessage(message) {
    setState({
      ...state,
      message,
    });
  }

  async function handleSubmit(e) {
    setState({
      ...state,
      loading: true,
    });
    e.preventDefault();
    const endpoint = 'https://sceglisicura.it/mail.php';
    const parameters = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: state.name,
        phone: state.phone,
        message: state.message,
      }),
    };

    try {
      const rawResponse = await fetch(endpoint, parameters);
      const response = await rawResponse.json();
      if (response && response.status === 'OK') {
        setState({
          ...state,
          loading: false,
          success: true,
        });
      }
      else throw new Error();
    } catch (e) {
      console.log(e);
      setState({
        ...state,
        loading: false,
        success: false,
      });
    }
  }

  return (
    <div className="contact-form">
      <div className="container">
        <div className="contact-form__title">
          <h3 className="heading-3">Ricevi una chiamata da un esperto</h3>
        </div>
        <div className="contact-form__content">
          <div className="contact-form__info-container">
            <div className="contact-form__info">
              <div className="contact-form__info-picture">
                <img srcSet={`
                     ${require('../../images/cangemi.png')} 1x,
                     ${require('../../images/cangemi@2x.png')} 2x,
                     ${require('../../images/cangemi@3x.png')} 3x,
                `}
                     alt="Foto di Gianluca Cangemi"
                     className="contact-form__info-img"/>
              </div>
              <div className="contact-form__info-text">
                <h5 className="heading-5">Gianluca Cangemi</h5>
                <span className="info-text">Intermediario assicurativo</span>
              </div>
            </div>
          </div>
          <div className="contact-form__form-container">
            <form
              action=""
              method=""
              onSubmit={handleSubmit}
              className="contact-form__form">
              <div className="contact-form__input-name">
                <TextInput
                  label="Nome"
                  name="name"
                  value={state.name}
                  setValue={handleName}
                  required />
              </div>
              <div className="contact-form__input-phone">
                <TextInput
                  label="Telefono"
                  name="phone"
                  type="tel"
                  value={state.phone}
                  setValue={handlePhone}
                  required />
              </div>
              <div className="contact-form__input-message">
                <TextInput
                  label="Inserisci la tua richiesta"
                  name="message"
                  value={state.message}
                  setValue={handleMessage}
                  required />
              </div>
              {(state.success === undefined && !state.loading) && (
                <div className="contact-form__bottom">
                  <div className="contact-form__privacy-policy">
                    Inviando una richiesta dichiari di accettare la
                    <a
                      href="/privacypolicy.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="contact-form__link">Privacy Policy</a>
                  </div>
                  <div className="contact-form__input-btn">
                    <input
                      type="submit"
                      value="Richiedi chiamata"
                      className="btn btn--accent btn--normal"
                    />
                  </div>
                </div>
              )}
              {(state.success !== undefined && !state.loading) && (
                <div className="contact-form__result-box">
                  <div className="contact-form__result-icon">
                    {
                      state.success ? (
                        <img
                          src={require('../../images/success.svg')}
                          alt="Simbolo di successo"
                          className="contact-form__result-img"
                        />
                        ) :
                        (
                          <img
                            src={require('../../images/error.svg')}
                            alt="Simbolo di errore"
                            className="contact-form__result-img"
                          />
                          )
                    }
                  </div>
                  <div className="contact-form__result-text">
                    <span className="contact-form__result-title">
                      {
                        state.success ?
                          'Richiesta inviata!' : 'Errore invio richiesta!'
                      }
                    </span>
                    <span className="contact-form__result-description">
                      {
                        state.success ?
                          'Verrai contattato a breve.' :
                          'Si è verificato un problema tecnico, riprova più tardi.'
                      }
                    </span>
                  </div>
                </div>
                )}
                {state.loading && (
                  <span
                    className="
                    contact-form__result-text
                    contact-form__result-description
                    ">
                    Invio in corso...
                  </span>
                )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
