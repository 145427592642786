import { useEffect } from "react"

// Animations

const heroSlide = {
  origin: 'top',
  distance: '100%',
  duration: 700,
  opacity: 1,
};

const slideFromBottom = {
  origin: 'bottom',
  distance: '50px',
  duration: 500,
};

const enterFromRight = {
  origin: 'right',
  distance: '100%',
  duration: 700,
  opacity: 1,
};

const noAnimation  = {
  distance: '0px',
  duration: 0,
  opacity: 1,
}


/**
 * @return {null}
 */
function Animations() {
  useEffect(() => {
    if (window) {
      const mobile = window.matchMedia('(max-width: 575.98px)').matches;
      // Definitions
      let ScrollReveal = require('scrollreveal').default;
      const sr = ScrollReveal();

      // COMMON ANIMATIONS
      // Hero
      sr.reveal('.hero', heroSlide);
      sr.reveal('.header', { ...slideFromBottom, delay: 700, duration: 400 });
      sr.reveal('.hero__text', {
        ...slideFromBottom,
        delay: 1200,
        afterReveal: (el) => el.style = 'visibility: visible',
      });
      sr.reveal('.hero__illustration, .partners', {
        ...slideFromBottom,
        delay: 1400,
        viewOffset: {
          bottom: -100,
        },
        afterReveal: (el) => el.style = 'visibility: visible',
      });
      sr.reveal('.hero__caret-down', {
        ...slideFromBottom,
        delay: 1500,
        afterReveal: (el) => el.style = 'visibility: visible',
      });

      // Services
      sr.reveal('.services__title', slideFromBottom);
      sr.reveal('.services-list__element', {
        ...slideFromBottom,
        delay: 500,
        interval: 150 });

      // Why Us
      sr.reveal('.why-us', { ...enterFromRight, viewFactor: 0.2 });
      sr.reveal('.why-us__title, .why-us__reason', {
        ...slideFromBottom,
        delay: 700,
        interval: 150,
      })


      // Contact form
      sr.reveal('.contact-form__title', slideFromBottom);
      sr.reveal(
        '.contact-form__info,' +
        '.contact-form__input-name,' +
        '.contact-form__input-phone,' +
        '.contact-form__input-message'
        , {
          ...slideFromBottom,
          delay: 500,
        },
      );
      sr.reveal('.contact-form__privacy-policy', { ...slideFromBottom, delay: 1000 });
      sr.reveal('.contact-form__input-btn', { ...slideFromBottom, delay: 1000 });

      // Contact Us
      sr.reveal('.contact-us', { ...enterFromRight });
      sr.reveal(
        '.contact-us__title,' +
        '.contact-us__contact', {
          ...slideFromBottom, delay: 500, interval: 150,
        }
      );

      // Footer
      sr.reveal('.footer__section', { ...slideFromBottom, interval: 150 });
      sr.reveal('.footer__bottom > *', { ...slideFromBottom, delay: 850 });

      // MOBILE ANIMATIONS
      if (mobile) {
        sr.reveal('.info__title', slideFromBottom);
        sr.reveal('.showcase__element-overlay--2', {
          ...noAnimation,
          afterReveal: (el) => el.classList.add('slide-to-left'),
        });
        sr.reveal('.showcase__element-overlay--1', {
          ...noAnimation,
          afterReveal: (el) => el.classList.add('slide-to-right'),
        });
        sr.reveal('.showcase__element-overlay--3', {
          ...noAnimation,
          afterReveal: (el) => el.classList.add('slide-to-top'),
        });
      }

      // DESKTOP ANIMATIONS
      else {
        // Info
        const showcaseImage1 = document.querySelector('.showcase__element-overlay--1');
        const showcaseImage2 = document.querySelector('.showcase__element-overlay--2');
        const showcaseImage3 = document.querySelector('.showcase__element-overlay--3');

        sr.reveal('.info', {
          ...noAnimation,
          viewFactor: mobile ? 0.2 : 0.6,
          afterReveal: () => {
            showcaseImage2.classList.add('slide-to-left');
            showcaseImage1.classList.add('slide-to-right');
            showcaseImage3.classList.add('slide-to-top');
            document.querySelectorAll('.info__title, .info__description > *')
              .forEach(el => el.classList.remove('slide-from-bottom'));
          }
        });
      }

    }
  }, []);

  return null;
}

export default Animations;

