import React from "react";
import ServicesList from "../servicesList/servicesList"

function Services() {
  return (
    <div className="services">
      <div className="container">
        <div className="services__content">
          <div className="services__title">
            <h2 className="heading-2">I nostri servizi assicurativi</h2>
          </div>
          <div className="services__services">
            <ServicesList />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
