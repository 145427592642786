import React from "react";

function Showcase() {
  return (
    <div className="showcase">
      <div className="showcase__left">
        <div className="showcase__element showcase__element--1">
          <div className="showcase__element-overlay showcase__element-overlay--1"></div>
          <img
            srcSet={`
          ${require('../../images/showcase-1.png')} 1x,
          ${require('../../images/showcase-1@2x.png')} 2x,
          ${require('../../images/showcase-1@3x.png')} 3x,
        `}
            alt=""
            className="showcase__image"/>
        </div>
      </div>
      <div className="showcase__right">
        <div className="showcase__element showcase__element--2">
          <div className="showcase__element-overlay showcase__element-overlay--2"></div>
          <img
            srcSet={`
          ${require('../../images/showcase-2.png')} 1x,
          ${require('../../images/showcase-2@2x.png')} 2x,
          ${require('../../images/showcase-2@3x.png')} 3x,
        `}
            alt=""
            className="showcase__image"/>
        </div>
        <div className="showcase__element showcase__element--3">
          <div className="showcase__element-overlay showcase__element-overlay--3"></div>
          <img
            srcSet={`
          ${require('../../images/showcase-3.png')} 1x,
          ${require('../../images/showcase-3@2x.png')} 2x,
          ${require('../../images/showcase-3@3x.png')} 3x,
        `}
            alt=""
            className="showcase__image"/>
        </div>
      </div>
    </div>
  );
}

export default Showcase;
